
.article-preview {
  min-height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  &_header {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .el-button {
      background-color: #2a3fe8;
      width: 100px;
      height: 32px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_content {
    padding: 16px 24px;
    color: #666666;
    font-size: 14px;
  }
}
